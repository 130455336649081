import { FunctionComponent, useEffect } from 'react';
import Spinner from '../../../../components/Spinner';
import styles from './styles.module.scss';
import StepDefinitionType from '../../Types/StepDefinition';
import Card from '../../../../components/Card';
import StepProps from '../../Types/StepProps';

const Sso: FunctionComponent<StepProps> = ({ loginValues }) => {
    useEffect(() => {
        if (loginValues.challengeParameters?.redirectUrl) {
            window.location.href = `${loginValues.challengeParameters.redirectUrl}&state=${encodeURIComponent(window.location.href)}`;
        }
    }, [loginValues.challengeParameters?.redirectUrl]);

    return (
        <Card className={styles.card} padded={false}>
            <div className={styles.container}>
                <div className={styles.inner}>
                    <Spinner innerClassName={styles.spinner} />
                </div>
            </div>
        </Card>
    );
};

export const StepDefinition: StepDefinitionType = {
    shouldMatch: (loginValues) => {
        if (loginValues.challengeName === 'SSO' && loginValues.challengeParameters?.redirectUrl) {
            return true;
        }

        return false;
    },
    StepComponent: Sso,
};

export default Sso;
